import { Badge, Button, Card, Page } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import UserHeader from '../../components/UserLayout/user-header';
import EditDeliveryBatchesTable from './edit-delivery-batches-table.component';
import axios from '../../axios';
import Cookie from 'js-cookie';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStyles } from './edit-delivery-batches-table.styles';
import Loading from '../../components/Loading/loading';
import {
  arraysAreEqual,
  disableStepsAccordingToBatchType,
  sortBatches,
} from '../../util/helpers';
import AlertDispatchMissingPendingPaymentDialog from './alert-dispatch-missing-pending-payment-dialog.component';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { Dangerous } from '@mui/icons-material';
import {
  DispatchesSubTypes,
  DispatchTypeCodes,
  DispatchColorPattern,
  DispatchProductionTypes,
} from '../../util/constants';
import UnlinkingWarningDialog from './unlinking-warning-dialog.component';

const EditDispatches = () => {
  const { id } = useParams();
  const colors = DispatchColorPattern;
  const [colorIndex, setColorIndex] = useState(0);
  const classes = useStyles();
  const [colorMapping, setColorMapping] = useState({});
  const [treatmentStages, setTreatmentStages] = useState([]);
  const [cuttingTechnicians, setCuttingTechnicians] = useState([]);
  const [laserCutlineTechnicians, setLaserCutlineTechnicians] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [batchesData, setBatchesData] = useState([]);
  const [dispatchesType, setDispatchesType] = useState(null);
  const [allowRetainerFlow, setAllowRetainerFlow] = useState(false);
  const [formRows, setFormRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDispatches, setLoadingDispatches] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [initialBatches, setInitialBatches] = useState([]);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [lastPaymentDate, setLastPaymentDate] = useState(null);
  const [warningPayments, setWarningPayment] = useState(false);
  const [splittingMode, setSplittingMode] = useState(false);
  const [linkingWarning, setLinkingWarning] = useState(false);
  const [unLinkingWarning, setUnLinkingWarning] = useState(false);
  const [openUnlinkingWarning, setOpenUnlinkingWarning] = useState(false);
  const location = useLocation();
  const history = useHistory();
  let linkState = {
    planType: '',
    clinicStatus: '',
    clinicName: '',
    status: '',
    userId: id,
    pageTitle: '',
    batches: [],
    dispatchesTypeData: null,
  };
  if (location.state !== undefined) {
    const {
      planType,
      clinicStatus,
      clinicName,
      status,
      userId,
      pageTitle,
      batches,
      dispatchesTypeData,
    } = location.state;
    linkState = {
      planType,
      clinicStatus,
      clinicName,
      status,
      userId,
      pageTitle,
      batches,
      dispatchesTypeData,
    };
  }
  const {
    planType,
    clinicStatus,
    clinicName,
    status,
    userId,
    pageTitle,
    batches,
    dispatchesTypeData,
  } = linkState;

  const validateSteps = (input, index) => {
    if (formRows[index].actualDispatchDate !== '-') {
      return true;
    }
    if (input && input !== 0 && input !== '') {
      input = input.trim();
      const stepPattern = /^(\d+(-\d+)?(,\d+(-\d+)?)*)?$/;
      let validFormat = stepPattern.test(input);
      if (!validFormat) {
        return false;
      }
      // Split the input by commas to get individual components
      const components = input.split(',');

      // Initialize an array to store all numbers
      const allNumbers = [];

      // Iterate over each component
      for (const component of components) {
        if (component.includes('-')) {
          // If it's a range, split by '-' to get start and end numbers
          const [start, end] = component.split('-').map(Number);

          // Check if the range is in ascending order
          if (start > end) {
            validFormat = false;
            break; // Return an empty array for invalid input
          }

          // Expand the range into individual numbers and add them to allNumbers
          for (let i = start; i <= end; i++) {
            allNumbers.push(i);
          }
        } else {
          // If it's a single number, parse and add it to allNumbers
          const number = parseInt(component, 10);
          if (!isNaN(number)) {
            allNumbers.push(number);
          }
        }
      }

      // Check for duplicates by converting the array to a Set and comparing lengths
      if (allNumbers.length !== new Set(allNumbers).size) {
        validFormat = false;
      }

      return validFormat;
    }

    return input === 0;
  };

  const updateBatch = async (status, dispatchId) => {
    try {
      setLoading(true);
      const response = await axios.put(
        `admin/v1/dispatches/${dispatchId}`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${Cookie.get('token')}`,
          },
        }
      );
      if (response.status === 200) {
        const { message, success } = response.data;
        if (!success) {
          toast.error(message, 3000);
        } else {
          toast.success(message, 3000);
          await getDispatches();
        }
      }
    } catch (error) {
      console.log('Error in dispatch production date status api', error);
      toast.error('An Error Occurred, please notify the Dev Team', 3000);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const formatBatches = (batches) => {
    if (batches.data && batches.data.length > 0) {
      const { data } = batches;
      const localColorMapping = {};
      let colorIndex = 0;
      // ! TODO fix for max and lower steps according to the smile plan
      let formattedBatches = data.map((batch, index) => {
        // Assigning Colors
        if (!localColorMapping[batch.delivery]) {
          localColorMapping[batch.delivery] = colors[colorIndex];
          colorIndex = (colorIndex + 1) % colors.length;
        }
        // Declaring Properties
        const type = batch?.type_code || DispatchTypeCodes['aligners'];
        const batchObject = {
          batchId: batch.id,
          parentId: batch.parent_id,
          color: localColorMapping[batch.delivery] || '#fff',
          lowerMaxSteps: batch?.smile_plan?.number_of_aligner_lower,
          upperMaxSteps: batch?.smile_plan?.number_of_aligner_upper,
          expectedStartOfLastStepInBatch:
            batch?.expected_start_of_last_step_in_batch || '-',
          number: batch.batch || '-',
          delivery: batch.delivery || index + 1,
          treatmentStage: batch?.smile_plan?.id || '-',
          targetDispatchDate: batch.target_dispatch_date || '-',
          actualDispatchDate: batch.actual_dispatch_date || '-',
          expectedReceiptDate: batch.expected_receipt_date || '-',
          actualReceiptDate: batch.actual_receipt_date || '-',
          expectedStartOfBatch: batch.expected_start_of_batch || '-',
          expectedLastPaymentDueDate:
            batch.expected_last_payment_due_date || '-',
          actualLastPaymentDueDate: batch.actual_last_payment_due_date || '-',
          isAligner:
            type === DispatchTypeCodes['aligners'] &&
            batch.sub_type === DispatchesSubTypes.standard,
          type,
          subType: batch.sub_type || '-',
          upperStepsFrom: 0,
          upperStepsTo: 0,
          upperProductQuantity: 0,
          upperPlasticOptions: '',
          upperSteps: 0,
          lowerStepsFrom: 0,
          lowerStepsTo: 0,
          lowerProductQuantity: 0,
          lowerPlasticOptions: '',
          lowerSteps: 0,
          cuttingTechnician: batch.cutting_technician || '-',
          laserCutlineTechnician: batch.laser_cutline_technician || '-',
          productionType: batch.production_type || '-',
          qualityControl: batch.quality_control,
          card: batch?.card,
          productionStartDate: batch.production_start_date || null,
          productionEndDate: batch.production_end_date || null,
          note: batch?.note || '',
          error: false,
          isDisabled: batch.disabled || false,
          isPickUpFromClinic: batch.is_pickup_from_clinic || false,
          isPaused: batch.is_paused || false,
          isIncentive: batch.is_incentive || false,
          isShopProduct: batch.is_shop_product || false,
          oldParentId: null,
        };
        // Declaring details
        batch?.details.forEach((detail) => {
          let stepsFrom = 0,
            stepsTo = 0,
            allSteps = 0;
          if (
            type === DispatchTypeCodes['aligners'] &&
            batch.sub_type === DispatchesSubTypes.standard
          ) {
            if (detail.steps && detail.steps.split('-').length > 0) {
              stepsFrom = detail.steps.split('-')[0];
              stepsTo = detail.steps.split('-')[1];
            }
          } else {
            allSteps = detail.steps ?? 0;
          }
          if (detail.is_upper) {
            batchObject.detailUpperId = detail.id;
            batchObject.upperStepsFrom = stepsFrom;
            batchObject.upperStepsTo = stepsTo;
            batchObject.upperProductQuantity = detail.quantity || 0;
            batchObject.upperPlasticOptions =
              detail.user_dispatch_plastic_code || '-';
            batchObject.upperSteps = allSteps;
          } else {
            batchObject.detailLowerId = detail.id;
            batchObject.lowerStepsFrom = stepsFrom;
            batchObject.lowerStepsTo = stepsTo;
            batchObject.lowerProductQuantity = detail.quantity || 0;
            batchObject.lowerPlasticOptions =
              detail.user_dispatch_plastic_code || '-';
            batchObject.lowerSteps = allSteps;
          }
        });
        return batchObject;
      });
      // Sorting according target dispatch date
      formattedBatches.sort(sortBatches);
      let lastPaymentDueDate = null;
      if (data && data.length > 0) {
        const dataLength = data.length - 1;
        if (
          data[dataLength] &&
          data[dataLength].payments &&
          data[dataLength].payments.length > 0
        ) {
          const paymentLength = data[dataLength].payments.length - 1;
          lastPaymentDueDate =
            data[dataLength].payments[paymentLength]?.due_date;
        }
      }
      setColorMapping(localColorMapping);
      setColorIndex(colorIndex);
      setLastPaymentDate(lastPaymentDueDate);
      setFormRows(formattedBatches);
      setInitialBatches(formattedBatches);
    }
  };

  const getDispatchesInformation = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `admin/v1/users/${id}/dispatches/information`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get('token')}`,
          },
        }
      );
      if (response.status === 200) {
        let {
          smilePlans,
          types,
          cuttingTechnicians,
          laserCutLineTechnicians,
          dispatchSubTypes,
          userStatus,
        } = response.data.data;
        setTreatmentStages(smilePlans);
        setCuttingTechnicians(cuttingTechnicians);
        setLaserCutlineTechnicians(laserCutLineTechnicians);
        setSubTypes(dispatchSubTypes);
        if (userStatus) {
          const { only_retainer, aligner_kit_purchased } = userStatus;
          if (only_retainer && !aligner_kit_purchased) {
            setAllowRetainerFlow(true);
            types = types.filter(
              (type) => type.code === DispatchTypeCodes.retainers,
            );
          }
        }
        setProductTypes(types);
        const checkIfWarning = smilePlans.some(
          (plan) => plan.payment_warning === true
        );
        if (checkIfWarning) {
          setWarningPayment(true);
        }
      }
    } catch (error) {
      console.log('Error in dispatch information api', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getDispatches = async () => {
    try {
      setLoadingDispatches(true);
      const response = await axios.get(`admin/v1/users/${id}/dispatches`, {
        headers: {
          Authorization: `Bearer ${Cookie.get('token')}`,
        },
      });
      if (response.status === 200) {
        setBatchesData(response.data);
        setDispatchesType(response.data?.dispatchesType);
      }
    } catch (error) {
      console.log('Error in dispatches api', error);
    } finally {
      setLoadingDispatches(false);
    }
  };

  const validateToSteps = (input, index, type, treatmentStage) => {
    if (formRows[index].actualDispatchDate !== '-') {
      return true;
    }
    if (
      formRows[index] &&
      type === 'lowerStepsFrom' &&
      formRows[index].lowerStepsFrom == 0
    ) {
      return true;
    }
    if (
      formRows[index] &&
      type === 'upperStepsFrom' &&
      formRows[index].upperStepsFrom == 0
    ) {
      return true;
    }
    // Check if the input is only number
    const stepNumberPattern = /^\d+$/;
    const isValid = stepNumberPattern.test(input);
    if (!isValid) {
      return false;
    }
    const inputToInt = parseInt(input);
    // Check if input is smaller than the to input
    if (inputToInt < formRows[index][type]) {
      return false;
    }
    // Check if input is out of range
    if (
      (inputToInt > formRows[index].lowerMaxSteps &&
        type === 'lowerStepsFrom') ||
      (inputToInt > formRows[index].upperMaxSteps &&
        type === 'upperStepsFrom') ||
      inputToInt <= 0
    ) {
      return false;
    }
    const getBeforeRows = formRows.slice(0, index);
    let isValidNumber = true;
    // Make sure the input is not smaller than the previous to
    if (getBeforeRows && getBeforeRows.length > 0) {
      for (const row of getBeforeRows) {
        if (row[type] >= inputToInt && row[treatmentStage] === treatmentStage) {
          isValidNumber = false;
          break;
        }
      }
    }

    return isValidNumber;
  };

  const validateBatches = () => {
    const typesWithPlastics = productTypes.filter(
      (type) => type?.plastic_types.length > 0
    );

    let isValid = true;

    const checkedFormRows = formRows.map((row) => {
      if (row.actualDispatchDate !== '-') {
        return { ...row, error: false };
      }
      if (row.type === '-') {
        isValid = false;
        return { ...row, error: true };
      }
      if (typesWithPlastics.some((plastic) => plastic.code === row.type)) {
        if (
          row.type + row.subType !==
          DispatchTypeCodes['aligners'] + DispatchesSubTypes['standard']
        ) {
          if (
            (row.upperSteps !== 0 && row.upperPlasticOptions === '-') ||
            (row.lowerSteps !== 0 && row.lowerPlasticOptions === '-')
          ) {
            isValid = false;
            return { ...row, error: true };
          }
        } else {
          if (
            (row.upperSteps !== 0 && row.upperPlasticOptions === '-') ||
            (row.lowerSteps !== 0 && row.lowerPlasticOptions === '-')
          ) {
            isValid = false;
            return { ...row, error: true };
          }
        }
      }

      if (
        !disableStepsAccordingToBatchType(row.type) &&
        row.type !== DispatchTypeCodes['aligners']
      ) {
        // Not Aligner
        if (row.upperSteps === 0 && row.lowerSteps === 0) {
          isValid = false;
          return { ...row, error: true };
        }
      }

      if (row.targetDispatchDate === '') {
        isValid = false;
        return { ...row, error: true };
      }

      return { ...row, error: false };
    });
    // If the last to Step is not equal to the max steps
    const previousFormRow = checkedFormRows[checkedFormRows.length - 1];
    if (
      previousFormRow.type === DispatchTypeCodes['aligners'] &&
      DispatchesSubTypes['standard'] === previousFormRow.subType
    ) {
      if (
        (parseInt(previousFormRow.lowerStepsTo) !==
          previousFormRow.lowerMaxSteps &&
          previousFormRow.lowerMaxSteps) ||
        (parseInt(previousFormRow.upperStepsTo) !==
          previousFormRow.upperMaxSteps &&
          previousFormRow.upperMaxSteps)
      ) {
        isValid = false;
        previousFormRow.error = true;
      }
    }

    setFormRows(checkedFormRows);
    return isValid;
  };

  const handleAddRetainerRow = () => {
    let treatmentStage = null;
    if (treatmentStages && treatmentStages.length > 0) {
      treatmentStage = treatmentStages[0].id;
    }
    let delivery = 1;
    const newRow = {
      number: "-",
      delivery,
      treatmentStage,
      type: "-",
      subType: "-",
      color: colors[0],
      parentId: null,
      expectedReceiptDate: "-",
      actualReceiptDate: "-",
      expectedStartOfBatch: "-",
      expectedStartOfLastStepInBatch: "-",
      expectedLastPaymentDueDate: "-",
      actualLastPaymentDueDate: "-",
      upperStepsFrom: 0,
      upperStepsTo: 0,
      lowerStepsFrom: 0,
      lowerStepsTo: 0,
      upperSteps: 0,
      lowerSteps: 0,
      upperPlasticOptions: "-",
      lowerPlasticOptions: "-",
      cuttingTechnician: "-",
      laserCutlineTechnician: "-",
      productionType: DispatchProductionTypes[0].value,
      qualityControl: false,
      productionStartDate: null,
      productionEndDate: null,
      targetDispatchDate: "",
      actualDispatchDate: "-",
      note: "",
      upperProductQuantity: 0,
      lowerProductQuantity: 0,
      error: false,
      isDisabled: false,
      isPaused: false,
      isPickUpFromClinic: false,
      isIncentive: false,
    };
    setFormRows([newRow]);
  };

  const formatSubmittedData = (data) => {
    data.sort(sortBatches);

    let deliveryCount = 1;
    return data.map((datum) => {
      let delivery;
      let details = [
        {
          id: datum.detailUpperId,
          is_upper: true,
          user_dispatch_plastic_code:
            datum.upperPlasticOptions === '-' ||
            datum.upperPlasticOptions === null
              ? 'none'
              : datum.upperPlasticOptions,
          steps: 0,
          quantity: null,
        },
        {
          id: datum.detailLowerId,
          is_upper: false,
          user_dispatch_plastic_code:
            datum.lowerPlasticOptions === '-' ||
            datum.lowerPlasticOptions === null
              ? 'none'
              : datum.lowerPlasticOptions,
          steps: 0,
          quantity: null,
        },
      ];
      if (!disableStepsAccordingToBatchType(datum.type)) {
        let upperSteps, lowerSteps;
        // Aligners
        if (
          datum.type === DispatchTypeCodes['aligners'] &&
          datum.subType === DispatchesSubTypes['standard']
        ) {
          upperSteps = `${datum.upperStepsFrom}-${datum.upperStepsTo}`;
          lowerSteps = `${datum.lowerStepsFrom}-${datum.lowerStepsTo}`;
        } else {
          upperSteps = datum.upperSteps;
          lowerSteps = datum.lowerSteps;
        }
        details[0].steps = upperSteps;
        details[1].steps = lowerSteps;
      } else {
        details[0].quantity = datum.upperProductQuantity;
        details[1].quantity = datum.lowerProductQuantity;
      }

      if (datum.parentId) {
        const parentIndex = data.findIndex(
          (item) => item.batchId === datum.parentId,
        );
        if (parentIndex !== -1) {
          delivery = data[parentIndex].delivery;
        }
      } else {
        delivery = deliveryCount;
        deliveryCount++;
      }

      return {
        ...datum,
        delivery,
        details,
      };
    });
  };

  const showWarningBeforeSaving = async () => {
    if (
      moment(formRows[formRows.length - 1].targetDispatchDate).isBefore(
        moment(lastPaymentDate)
      )
    ) {
      setOpenWarningDialog(true);
    } else if (unLinkingWarning) {
      setOpenUnlinkingWarning(true);
    } else {
      await postDispatchData(warningPayments);
    }
  };

  const postDispatchData = async (isWarning = false) => {
    const isValid = validateBatches();
    let checkAlignersStepNumbers = true;
    for (let i = 0; i < formRows.length; i++) {
      if (
        formRows[i].type === DispatchTypeCodes['aligners'] &&
        formRows[i].subType === DispatchesSubTypes['standard']
      ) {
        if (
          !validateToSteps(
            formRows[i].upperStepsTo,
            i,
            'upperStepsFrom',
            formRows[i].treatmentStage
          ) ||
          !validateToSteps(
            formRows[i].lowerStepsTo,
            i,
            'lowerStepsFrom',
            formRows[i].treatmentStage
          )
        ) {
          checkAlignersStepNumbers = false;
          break;
        }
      } else if (
        !disableStepsAccordingToBatchType(formRows[i].type) &&
        formRows[i].type !== DispatchTypeCodes['aligners']
      ) {
        if (
          !validateSteps(formRows[i].upperSteps, i) ||
          !validateSteps(formRows[i].lowerSteps, i)
        ) {
          checkAlignersStepNumbers = false;
          break;
        }
      }
    }
    if (!isValid || !checkAlignersStepNumbers) {
      toast.error('Check Your Inputs');
      return;
    }
    try {
      setPostLoading(true);
      const data = formatSubmittedData(formRows);
      const abortController = new AbortController();
      const { signal } = abortController;
      const response = await axios.post(
        `admin/v1/users/${id}/dispatches/${isWarning}/${splittingMode}`,
        { data, warnings: { linkingWarning, unLinkingWarning } },
        {
          headers: { 
            Authorization: `Bearer ${Cookie.get('token')}`,
          },
          signal,
        }
      );
      if (response.status === 200) {
        setSplittingMode(false);
        setLinkingWarning(false);
        setUnLinkingWarning(false);
        const responseData = response.data;
        const { message, warning, paymentWarning } = response.data;
        if (!warning) {
          if (location.state) {
            const updatedLocation = { ...location };
            updatedLocation.state.batches = responseData;
            updatedLocation.state.dispatchesTypeData =
              responseData?.dispatchesType;
            history.push(updatedLocation);
            formatBatches(updatedLocation.state.batches);
          } else {
            formatBatches(responseData);
          }
          setDispatchesType(responseData?.dispatchesType);
          toast.success(message);
          if (paymentWarning !== warningPayments) {
            setWarningPayment(paymentWarning);
            setOpenWarningDialog(paymentWarning);
          }
        }
      }
    } catch (error) {
      console.error('Error in storing dispatches api', error);
      toast.error('An Error Occurred, please notify the Dev Team');
    } finally {
      setPostLoading(false);
    }
  };

  useEffect(() => {
    void getDispatches();
    if (batches?.data && batches?.data.length > 0) {
      setBatchesData(batches);
    }
  }, []);

  useEffect(() => {
    if (dispatchesTypeData) {
      setDispatchesType(dispatchesTypeData);
    }
  }, []);

  useEffect(() => {
    void getDispatchesInformation();
  }, []);

  let noteWarning = '';
  if (formRows && formRows.length > 0) {
    noteWarning = formRows[formRows.length - 1].note;
  }

  if (loading || loadingDispatches) {
    return <Loading />;
  }

  return (
    <Page fullWidth>
      <ToastContainer />
      <UserHeader
        pageTitle={pageTitle}
        status={status}
        userId={userId}
        clinicStatus={clinicStatus}
        clinicName={clinicName}
        planType={planType}
      />
      <br />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <h1
          style={{
            fontSize: '24px',
            display: 'flex',
            columnGap: '8px',
            alignItems: 'center',
          }}
        >
          Edit Delivery Batches
          {dispatchesType && <Badge status="success">{dispatchesType}</Badge>}
        </h1>
        <div className={classes.iconsContainer}>
          {warningPayments && (
            <>
              <span>Pending payments are outside dispatch schedule</span>
              <Tooltip
                classes={{ tooltip: classes.tootTip }}
                title="Pending payments are outside dispatch schedule"
              >
                <Dangerous fontSize="large" color="error" />
              </Tooltip>
            </>
          )}
          {allowRetainerFlow && formRows.length === 0 && (
            <Button
              primary
              onClick={handleAddRetainerRow}
            >
              Add Retainer
            </Button>
          )}
          <Button
            loading={postLoading}
            primary
            onClick={showWarningBeforeSaving}
            disabled={arraysAreEqual(initialBatches, formRows)}
          >
            Save
          </Button>
        </div>
      </div>
      <Card sectioned>
        <EditDeliveryBatchesTable
          batches={batchesData}
          getDispatchesInformation={getDispatchesInformation}
          treatmentStages={treatmentStages}
          cuttingTechnicians={cuttingTechnicians}
          laserCutlineTechnicians={laserCutlineTechnicians}
          subTypes={subTypes}
          productTypes={productTypes}
          formRows={formRows}
          setFormRows={setFormRows}
          validateToSteps={validateToSteps}
          formatBatches={formatBatches}
          validateSteps={validateSteps}
          initialBatches={initialBatches}
          colorMapping={colorMapping}
          colors={colors}
          colorIndex={colorIndex}
          setColorIndex={setColorIndex}
          setSplittingMode={setSplittingMode}
          setLinkingWarning={setLinkingWarning}
          setUnLinkingWarning={setUnLinkingWarning}
          updateBatch={updateBatch}
        />
        <UnlinkingWarningDialog
          open={openUnlinkingWarning}
          setOpen={setOpenUnlinkingWarning}
          postDispatchData={postDispatchData}
        />
        <AlertDispatchMissingPendingPaymentDialog
          open={openWarningDialog}
          setOpen={setOpenWarningDialog}
          postDispatchData={postDispatchData}
          note={noteWarning}
          setFormRows={setFormRows}
          formRows={formRows}
        />
      </Card>
    </Page>
  );
};

export default EditDispatches;
